$(document).ready(function() {
    $('select').niceSelect();


    $('.input-field input').on('input', function() {
        if($(this).val() === '') {
            $(this).removeClass('filled');
        } else {
            $(this).addClass('filled');
        }
    })

    $('.input-field input').each(function() {
        if($(this).val() === '') {
            $(this).removeClass('filled');
        } else {
            $(this).addClass('filled');
        }
    })

    $('.nice-select .option').on('click', function() {
        $(this).parents('.nice-select').addClass('filled');
    });


    $('.start-search').on('click', function(e) {
        e.preventDefault();

        let form = $(this).parents('.s-form');
        form.hide();
        $('.s-form-confirmation').show();

        $('html, body').animate({
            scrollTop: 0,
        }, 400)
    });


    $('.accordion-item').on('click', function(e) {
        e.preventDefault();

        let parent = $(this);

        parent.toggleClass('active');
        parent.find('.accordion-item-body').slideToggle(400);
    });



});
